<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :filterFields="filterFields"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showViewButton
    @edit="(id) => onEdit('OrganizationEdit', id)"
  >
    <template v-slot:contratcsCount="{ row }">
      {{ contracts[row.inn] }}
    </template>
  </sm-editable-list>
</template>

<script>
// vue
import Vue from 'vue';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'Organizations',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Organizations',
      tableCaption: 'Организации',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Организации',
        },
      ],
      filterFields: [
        { text: 'ИНН', alias: 'inn', order: 'inn' },
        { text: 'Полное наименование', alias: 'fullName', order: 'fullName' },
        {
          text: 'Сокращенное наименование',
          alias: 'shortName',
          order: 'shortName',
        },
        { text: 'GUID', alias: 'guid', order: 'guid' },
        {
          text: 'Имя начальника',
          alias: 'chiefFirstName',
          order: 'chiefFirstName',
        },
        {
          text: 'Отчество начальника',
          alias: 'chiefMiddleName',
          order: 'chiefMiddleName',
        },
        {
          text: 'Фамилия начальника',
          alias: 'chiefLastName',
          order: 'chiefLastName',
        },
        { text: 'ОГРН', alias: 'ogrn', order: 'ogrn' },
        { text: 'КПП', alias: 'kpp', order: 'kpp' },
        { text: 'Код банка', alias: 'bankCode', order: 'bankCode' },
        {
          text: 'Количество домов',
          alias: 'totalHouses',
          order: 'totalHouses',
        },
        {
          text: 'Количество жилых помещений',
          alias: 'residentialPremiseCount',
          order: 'residentialPremiseCount',
        },
        {
          text: 'Количество нежилых помещений',
          alias: 'nonResidentialPremiseCount',
          order: 'nonResidentialPremiseCount',
        },
        {
          text: 'Количество жилых помещений фактическое значение',
          alias: 'residentialPremiseActualCount',
          order: 'residentialPremiseActualCount',
        },
        {
          text: 'Количество нежилых помещений',
          alias: 'nonResidentialPremiseActualCount',
          order: 'nonResidentialPremiseActualCount',
        },
        {
          text: 'Подтвержденное количество жилых помещений',
          alias: 'residentialPremiseConfirmedCount',
          order: 'residentialPremiseConfirmedCount',
        },
        {
          text: 'Подтвержденное количество нежилых помещений',
          alias: 'nonResidentialPremiseConfirmedCount',
          order: 'nonResidentialPremiseConfirmedCount',
        },
        {
          text: 'Количество аккаунтов',
          alias: 'accountCount',
          order: 'accountCount',
        },
        { text: 'Количество страниц', alias: 'page', order: 'page' },
        { text: 'Адрес', alias: 'address', order: 'address' },
        { text: 'Телефон', alias: 'phone', order: 'phone' },
        { text: 'Сайт', alias: 'url', order: 'url' },
        { text: 'Код региона', alias: 'regionCode', order: 'regionCode' },
        {
          text: 'Наименование региона',
          alias: 'regionName',
          order: 'regionName',
        },
        {
          text: 'Короткое наименование региона',
          alias: 'regionShortName',
          order: 'regionShortName',
        },
        { text: 'Наименование области', alias: 'areaName', order: 'areaName' },
        { text: 'Наименование города', alias: 'cityName', order: 'cityName' },
        {
          text: 'Сокращенное наименование города',
          alias: 'cityShortName',
          order: 'cityShortName',
        },
        { text: 'Наименование города', alias: 'cityName', order: 'cityName' },
        {
          text: 'Фактический адрес',
          alias: 'actualAddress',
          order: 'actualAddress',
        },
        {
          text: 'Электронная почта',
          alias: 'email',
          order: 'email',
        },
        { text: 'Наименование роли', alias: 'roleName', order: 'roleName' },
        {
          text: 'Дата регистрации на ГИС ЖКХ',
          alias: 'dateOfRegestrionGisJkh',
          order: 'dateOfRegestrionGisJkh',
        },
        { text: 'Создано', alias: 'created', order: 'created' },
      ],
      tableHeaders: [
        { text: 'GUID', alias: 'guid', order: 'guid' },
        { text: 'Полное наименование', alias: 'fullName', order: 'fullName' },
        {
          text: 'Сокращенное наименование',
          alias: 'shortName',
          order: 'shortName',
        },
        {
          text: 'Имя начальника',
          alias: 'chiefFirstName',
          order: 'chiefFirstName',
        },
        {
          text: 'Отчество начальника',
          alias: 'chiefMiddleName',
          order: 'chiefMiddleName',
        },
        {
          text: 'Фамилия начальника',
          alias: 'chiefLastName',
          order: 'chiefLastName',
        },
        { text: 'ИНН', alias: 'inn', order: 'inn' },
        { text: 'ОГРН', alias: 'ogrn', order: 'ogrn' },
        { text: 'КПП', alias: 'kpp', order: 'kpp' },
        { text: 'Код банка', alias: 'bankCode', order: 'bankCode' },
        {
          text: 'Количество домов',
          alias: 'totalHouses',
          order: 'totalHouses',
        },
        {
          text: 'Количество жилых помещений',
          alias: 'residentialPremiseCount',
          order: 'residentialPremiseCount',
        },
        {
          text: 'Количество нежилых помещений',
          alias: 'nonResidentialPremiseCount',
          order: 'nonResidentialPremiseCount',
        },
        {
          text: 'Количество жилых помещений фактическое значение',
          alias: 'residentialPremiseActualCount',
          order: 'residentialPremiseActualCount',
        },
        {
          text: 'Количество нежилых помещений',
          alias: 'nonResidentialPremiseActualCount',
          order: 'nonResidentialPremiseActualCount',
        },
        {
          text: 'Подтвержденное количество жилых помещений',
          alias: 'residentialPremiseConfirmedCount',
          order: 'residentialPremiseConfirmedCount',
        },
        {
          text: 'Подтвержденное количество нежилых помещений',
          alias: 'nonResidentialPremiseConfirmedCount',
          order: 'nonResidentialPremiseConfirmedCount',
        },
        {
          text: 'Количество аккаунтов',
          alias: 'accountCount',
          order: 'accountCount',
        },
        { text: 'Количество страниц', alias: 'page', order: 'page' },
        { text: 'Адрес', alias: 'address', order: 'address' },
        { text: 'Телефон', alias: 'phone', order: 'phone' },
        { text: 'Сайт', alias: 'url', order: 'url' },
        { text: 'Код региона', alias: 'regionCode', order: 'regionCode' },
        {
          text: 'Наименование региона',
          alias: 'regionName',
          order: 'regionName',
        },
        {
          text: 'Короткое наименование региона',
          alias: 'regionShortName',
          order: 'regionShortName',
        },
        { text: 'Наименование области', alias: 'areaName', order: 'areaName' },
        { text: 'Наименование города', alias: 'cityName', order: 'cityName' },
        {
          text: 'Сокращенное наименование города',
          alias: 'cityShortName',
          order: 'cityShortName',
        },
        { text: 'Наименование города', alias: 'cityName', order: 'cityName' },
        {
          text: 'Фактический адрес',
          alias: 'actualAddress',
          order: 'actualAddress',
        },
        {
          text: 'Электронная почта',
          alias: 'email',
          order: 'email',
        },
        { text: 'Наименование роли', alias: 'roleName', order: 'roleName' },
        {
          text: 'Дата регистрации на ГИС ЖКХ',
          alias: 'dateOfRegestrionGisJkh',
          order: 'dateOfRegestrionGisJkh',
        },
        { text: 'Создано', alias: 'created', order: 'created' },
        { text: 'Количество контрактов', alias: 'contratcsCount' },
        { alias: 'actions' },
      ],
      contracts: {},
    };
  },

  computed: {
    ...mapState(['list']),
  },

  watch: {
    'list.data': {
      handler() {
        const list = this.list.data;
        if (!list) return;
        list.items.forEach((item) => {
          this.getContratcsCount(item.inn);
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['getChildList']),

    async getContratcsCount(inn) {
      if (!inn) return;
      const contractForInn = await this.getChildList({
        name: 'oneCContracts',
        parentName: 'Inn',
        parentId: inn,
        params: {
          skip: 0,
          count: 150,
        },
      });

      Vue.set(this.contracts, inn, contractForInn.length);
    },
  },
};
</script>
